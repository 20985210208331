<template>
  <v-dialog v-model="dialog" width="700">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        plain
        color="primary"
        style="padding-left: 8px; padding-right: 8px"
        v-bind="attrs"
        v-on="on"
      >
        {{ codBarras }}
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="primary white--text">
        Movimentação - {{ codBarras }}
      </v-card-title>

      <v-card-text class="pt-5 px-6">
        <div class="text-center my-8" v-if="loading">
          <v-progress-circular indeterminate />
        </div>

        <v-timeline v-else-if="data">
          <v-timeline-item
            v-for="(d, i) in data"
            :key="i"
            :color="
              d.status == 'ENVIADO'
                ? 'accent'
                : d.status == 'ENTREGUE'
                ? 'success'
                : 'primary'
            "
            :icon="
              d.status == 'ENVIADO'
                ? 'mdi-truck-fast'
                : d.status == 'ENTREGUE'
                ? 'mdi-truck-check'
                : 'mdi-warehouse'
            "
            fill-dot
          >
            <span
              slot="opposite"
              :class="`subtitle-1 font-weight-bold ${
                d.status == 'ENVIADO'
                  ? 'accent'
                  : d.status == 'ENTREGUE'
                  ? 'success'
                  : 'primary'
              }--text`"
            >
              {{ d.data_hora }}
            </span>
            <div class="py-4">
              <div
                :class="`subtitle-1 font-weight-light mb-1 ${
                  d.status == 'ENVIADO'
                    ? 'accent'
                    : d.status == 'ENTREGUE'
                    ? 'success'
                    : 'primary'
                }--text`"
              >
                <b class="text-capitalize">
                  Status:
                  {{ d.status.toLowerCase() }}
                </b>
              </div>

              <div class="body-2 font-weight-light text-capitalize black--text">
                <div>
                  <b>Origem:</b>
                  {{ cityCase(d.origem) }}
                </div>
                <div>
                  <b>Destino:</b>
                  {{ cityCase(d.destino) }}
                </div>
                <div v-if="d.status != 'ENTREGUE'">
                  <b>
                    Agência:
                    <span
                      :style="`color:${
                        d.status == 'ENVIADO'
                          ? '#ccae62'
                          : d.status == 'ENTREGUE'
                          ? 'green'
                          : '#2054AF'
                      }`"
                    >
                      {{ d.localidade.toLowerCase() }} -
                      {{ d.estado_localidade }}
                    </span>
                  </b>
                </div>
                <div v-if="d.status == 'ENTREGUE'">
                  <b style="color: green">
                    Entregue
                    {{
                      d.entregue_por == 1 ? "pelo motorista" : "pela agência"
                    }}
                    ao cliente
                  </b>
                </div>
              </div>
            </div>
          </v-timeline-item>
        </v-timeline>

        <div class="text-center my-8" v-else>
          <span class="error--text">Falha na busca de dados!</span>
        </div>
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" text @click="dialog = false">Fechar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Helpers from "@/mixins";
export default {
  mixins: [Helpers],
  props: ["codBarras"],
  data: () => ({
    dialog: false,
    dados: null,
    loading: true,
  }),
  watch: {
    dialog: function (open) {
      if (open) {
        this.loading = true;
        this.$http
          .get(`/encomendas/rastreamento/${this.codBarras}`)
          .then((res) => {
            this.data = res.data;
          })
          .catch(() => {
            this.data = null;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
};
</script>
