var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"700"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"padding-left":"8px","padding-right":"8px"},attrs:{"plain":"","color":"primary"}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.codBarras)+" ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"primary white--text"},[_vm._v(" Movimentação - "+_vm._s(_vm.codBarras)+" ")]),_c('v-card-text',{staticClass:"pt-5 px-6"},[(_vm.loading)?_c('div',{staticClass:"text-center my-8"},[_c('v-progress-circular',{attrs:{"indeterminate":""}})],1):(_vm.data)?_c('v-timeline',_vm._l((_vm.data),function(d,i){return _c('v-timeline-item',{key:i,attrs:{"color":d.status == 'ENVIADO'
              ? 'accent'
              : d.status == 'ENTREGUE'
              ? 'success'
              : 'primary',"icon":d.status == 'ENVIADO'
              ? 'mdi-truck-fast'
              : d.status == 'ENTREGUE'
              ? 'mdi-truck-check'
              : 'mdi-warehouse',"fill-dot":""}},[_c('span',{class:("subtitle-1 font-weight-bold " + (d.status == 'ENVIADO'
                ? 'accent'
                : d.status == 'ENTREGUE'
                ? 'success'
                : 'primary') + "--text"),attrs:{"slot":"opposite"},slot:"opposite"},[_vm._v(" "+_vm._s(d.data_hora)+" ")]),_c('div',{staticClass:"py-4"},[_c('div',{class:("subtitle-1 font-weight-light mb-1 " + (d.status == 'ENVIADO'
                  ? 'accent'
                  : d.status == 'ENTREGUE'
                  ? 'success'
                  : 'primary') + "--text")},[_c('b',{staticClass:"text-capitalize"},[_vm._v(" Status: "+_vm._s(d.status.toLowerCase())+" ")])]),_c('div',{staticClass:"body-2 font-weight-light text-capitalize black--text"},[_c('div',[_c('b',[_vm._v("Origem:")]),_vm._v(" "+_vm._s(_vm.cityCase(d.origem))+" ")]),_c('div',[_c('b',[_vm._v("Destino:")]),_vm._v(" "+_vm._s(_vm.cityCase(d.destino))+" ")]),(d.status != 'ENTREGUE')?_c('div',[_c('b',[_vm._v(" Agência: "),_c('span',{style:(("color:" + (d.status == 'ENVIADO'
                        ? '#ccae62'
                        : d.status == 'ENTREGUE'
                        ? 'green'
                        : '#2054AF')))},[_vm._v(" "+_vm._s(d.localidade.toLowerCase())+" - "+_vm._s(d.estado_localidade)+" ")])])]):_vm._e(),(d.status == 'ENTREGUE')?_c('div',[_c('b',{staticStyle:{"color":"green"}},[_vm._v(" Entregue "+_vm._s(d.entregue_por == 1 ? "pelo motorista" : "pela agência")+" ao cliente ")])]):_vm._e()])])])}),1):_c('div',{staticClass:"text-center my-8"},[_c('span',{staticClass:"error--text"},[_vm._v("Falha na busca de dados!")])])],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v("Fechar")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }